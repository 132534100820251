import Alpine from "alpinejs"
import collapse from "@alpinejs/collapse"
import Lenis from "@studio-freight/lenis"

Alpine.plugin(collapse)
import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  EffectFade,
  Scrollbar,
} from "swiper"

import "swiper/css"
//import "swiper/css/navigation";
//import "swiper/css/pagination";
import "swiper/css/effect-fade"
import "swiper/css/autoplay"
import "swiper/css/scrollbar"
import "overlayscrollbars/overlayscrollbars.css"
import { OverlayScrollbars } from "overlayscrollbars"

Swiper.use([Pagination, EffectFade, Autoplay, Navigation, Scrollbar])
window.Swiper = Swiper

window.Alpine = Alpine
window.OverlayScrollbars = OverlayScrollbars

Alpine.start()

OverlayScrollbars(document.body, {
  overflow: {
    x: "hidden",
  },
})

const lenis = new Lenis()

function raf(time) {
  lenis.raf(time)
  requestAnimationFrame(raf)
}

requestAnimationFrame(raf)
